<template>
  <div id="resend">
    <invoice-header text="重发发票"></invoice-header>

    <div class="rows">
      <span>接收方式</span>
    </div>
    <div class="rows email">
      <span>电子邮件</span>
      <input type="text" v-model="email" placeholder="用于向您发送电子发票" maxlength="50" />
    </div>
    <p>说明：请输入新邮箱，再次点击提交后系统会给您重新发送电子发票。</p>

    <button @click="onSubmit">提交</button>
  </div>
</template>

<script>
import util from "../../commons/util";
import { resend } from "./invoice";

export default {
  components: {
    "invoice-header": require("./header").default
  },
  created() {
    let InvoiceDetail = sessionStorage.getItem("InvoiceDetail");
    if (InvoiceDetail) {
      this.invoiceDetail = JSON.parse(InvoiceDetail);
    }
  },
  data() {
    return {
      invoiceDetail: null,
      email: ""
    };
  },
  methods: {
    async onSubmit() {
      let reg = /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/;
      if (!reg.test(this.email)) {
        util.toast("电子邮件格式不正确");
        return;
      }
      let res = await resend(this.invoiceDetail.fpqqlsh, this.email);
      if (res.ret == "0") {
        util.toast(res.message);
      } else {
        sessionStorage.setItem(
          "ReturnInfo",
          JSON.stringify({
            page: "开票历史",
            routeName: "invoice_history"
          })
        );
        this.$router.push({ name: "invoice_order_success" });
      }
    }
  }
};
</script>

<style lang="scss">
#resend {
  background-color: #f4f4f4;
  min-height: 100vh;

  .rows {
    height: 0.8rem;
    margin: 0 auto;
    padding-left: 0.31rem;
    padding-right: 0.27rem;
    display: flex;
    align-items: center;
    font-size: 0.22rem;
    color: #333333;

    span {
      margin-right: 1em;
    }

    input {
      flex: 1;
      border: none;
      outline: none;
    }

    &.email {
      background-color: #fff;
      border-top: 0.01rem solid #e6e6e6;
      border-bottom: 0.01rem solid #e6e6e6;
    }

    &.prompt {
      color: #999999;
    }
  }

  p {
    font-size: 0.22rem;
    margin-top: 0.31rem;
    margin-bottom: 0.35rem;
    padding-left: 0.31rem;
    padding-right: 0.27rem;
  }

  button {
    width: 91.667%;
    height: 0.8rem;
    margin: 0 auto;
    margin-left: 0.26rem;
    display: block;
    background-color: #20a3dc;
    border-radius: 0.06rem;
    border: none;
    outline: none;
    font-size: 0.36rem;
    color: #ffffff;
  }
}
</style>
